import React, {Component} from "react";
import PropTypes from "prop-types";
import 'video.js/dist/video-js.css';
import videojs from "video.js";

class Video extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const {question} = this.props;
		this.player = videojs(this.videoNode, this.props, () => {
		});
		const screenWidth = document.getElementById(question.id + "-video-container").offsetWidth;
		const isMobile = screenWidth < question.playerWidth;
		this.player.fluid(isMobile)
		this.player.width(question.playerWidth)
		this.player.height(question.playerHeight)
		this.player.autoplay(question.autoPlay)
		if (question.required) {
			this.player.controlBar.progressControl.hide();
		}
		setInterval(() => {
			const screenWidth = document.getElementById(question.id + "-video-container").offsetWidth;
			const isMobile = screenWidth < question.playerWidth;
			this.player.fluid(isMobile)
		}, 1000)
	}

	// Dispose the player when the component will unmount
	componentWillUnmount() {
		if (this.player) {
			this.player.dispose();
		}
	}

	render() {
		const {question} = this.props;
		let type;
		if (question.video.toLowerCase().endsWith('webm')) {
			type = 'video/webm';
		} else {
			type = "video/" + (question.video ? question.video.substr(question.video.length - 3) : 'mp4')
		}

		return (
			<div style={{textAlign: "center"}} className="mx-auto pb-2" id={question.id + "-video-container"}>
				<div data-vjs-player>
					<video ref={node => this.videoNode = node} data-setup='{"controls": true, "preload": "auto"}' className="video-js">
						<source src={question.video} type={type}/>
					</video>
				</div>
			</div>
		)
	}
}

Video.propTypes = {
	question: PropTypes.shape({
		video: PropTypes.string.isRequired,
		required: PropTypes.bool.isRequired,
		autoPlay: PropTypes.bool.isRequired,
		autoNextPage: PropTypes.bool.isRequired,
		playerWidth: PropTypes.number.isRequired,
		playerHeight: PropTypes.number.isRequired,
	}).isRequired,
};

export default Video