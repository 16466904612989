import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import "react-input-range/lib/css/index.css";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import api from 'api';
import FormDefaultProperty from "../../FormDefaultProperty";
import CollectorPanelForm from "./CollectorPanelForm";
import _ from "lodash";
import axios from "axios";
import {Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import FormGroup from "reactstrap/es/FormGroup";
import Switch from "components/Switch";
import {toast} from "react-toastify";
import Button from "../../../../../components/Button";
import ModalSimple from "../../../../../componentsReusable/ModalSimple";
import {connect} from "react-redux";

const CancelToken = axios.CancelToken;

class TabTarget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        isHidden: props.collector.isHidden ? props.collector.isHidden : null,
        guid: props.collector.guid ? props.collector.guid : null,
        startAt: props.collector.startAt ? moment(props.collector.startAt).format('YYYY-MM-DD') : null,
        endAt: props.collector.endAt ? moment(props.collector.endAt).format('YYYY-MM-DD') : null,
        collectorResponses: props.collector.collectorResponses ? props.collector.collectorResponses : null,
        lastActivityFrom: props.collector.lastActivityFrom ? props.collector.lastActivityFrom : null,
        lastActivityTo: props.collector.lastActivityTo ? props.collector.lastActivityTo : null,
        minAge: props.collector.minAge ? props.collector.minAge : null,
        maxAge: props.collector.maxAge ? props.collector.maxAge : null,
        sexF: props.collector.sexF ? props.collector.sexF : null,
        sexM: props.collector.sexM ? props.collector.sexM : null,
        excludedUsers: props.collector.excludedUsers ? props.collector.excludedUsers : [],
        onlyUsers: props.collector.onlyUsers ? props.collector.onlyUsers : [],
        endedSurveys: props.collector.endedSurveys ? props.collector.endedSurveys : [],
        notEndedSurveys: props.collector.notEndedSurveys ? props.collector.notEndedSurveys : [],
        userIdFrom: props.collector.userIdFrom ? props.collector.userIdFrom : null,
        userIdTo: props.collector.userIdTo ? props.collector.userIdTo : null,
        cashPointsFrom: props.collector.cashPointsFrom ? props.collector.cashPointsFrom : null,
        cashPointsTo: props.collector.cashPointsTo ? props.collector.cashPointsTo : null,
        cities: props.collector.cities ? props.collector.cities : null,
        cityVillage: props.collector.cityVillage ? props.collector.cityVillage : null,
        cityTown: props.collector.cityTown ? props.collector.cityTown : null,
        cityProvinces: props.collector.cityProvinces ? props.collector.cityProvinces : null,
        cityDistricts: props.collector.cityDistricts ? props.collector.cityDistricts : null,
        cityLmFrom: props.collector.cityLmFrom ? props.collector.cityLmFrom : null,
        cityLmTo: props.collector.cityLmTo ? props.collector.cityLmTo : null,
        postalCodes: props.collector.postalCodes ? props.collector.postalCodes : [],
        profileQuestionDevices: props.collector.profileQuestionDevices ? props.collector.profileQuestionDevices : [],
        profileQuestionEducation: props.collector.profileQuestionEducation ? props.collector.profileQuestionEducation : [],
        profileQuestionProfessionalStatus: props.collector.profileQuestionProfessionalStatus ? props.collector.profileQuestionProfessionalStatus : [],
        profileQuestionIsPeopleManager: props.collector.profileQuestionIsPeopleManager ? props.collector.profileQuestionIsPeopleManager : [],
        profileQuestionIsDecisionMakerBuyingAtWork: props.collector.profileQuestionIsDecisionMakerBuyingAtWork ? props.collector.profileQuestionIsDecisionMakerBuyingAtWork : [],
        profileQuestionHouseholdComposition: props.collector.profileQuestionHouseholdComposition ? props.collector.profileQuestionHouseholdComposition : [],
        profileQuestionIsCarUser: props.collector.profileQuestionIsCarUser ? props.collector.profileQuestionIsCarUser : [],
        profileQuestionIsPetKeeper: props.collector.profileQuestionIsPetKeeper ? props.collector.profileQuestionIsPetKeeper : [],
        profileQuestionCurrentWork: props.collector.profileQuestionCurrentWork ? props.collector.profileQuestionCurrentWork : [],
        profileQuestionHouseholdContainUnder18Years: props.collector.profileQuestionHouseholdContainUnder18Years ? props.collector.profileQuestionHouseholdContainUnder18Years : [],
        profileQuestionIsDecisionMakerBuying: props.collector.profileQuestionIsDecisionMakerBuying ? props.collector.profileQuestionIsDecisionMakerBuying : [],
        profileQuestionRespondentIncome: props.collector.profileQuestionRespondentIncome ? props.collector.profileQuestionRespondentIncome : [],
        profileQuestionMainIndustry: props.collector.profileQuestionMainIndustry ? props.collector.profileQuestionMainIndustry : [],
        profileQuestionHouseholdIncomePerPerson: props.collector.profileQuestionHouseholdIncomePerPerson ? props.collector.profileQuestionHouseholdIncomePerPerson : [],
        profileQuestionIsOwnerRtvAgd: props.collector.profileQuestionIsOwnerRtvAgd ? props.collector.profileQuestionIsOwnerRtvAgd : [],
        respondentsMax: props.collector.respondentsMax ? props.collector.respondentsMax : 0,
        countScreenouts: props.collector.countScreenouts ? props.collector.countScreenouts : null,
        endCashPointsEmail: props.collector.endCashPointsEmail ? this.props.collector.endCashPointsEmail : null,
        endCashPoints: props.collector.endCashPoints ? this.props.collector.endCashPoints : null
      },
      axiosCancelTokenSource: false,
      probablyRange: 0,
      saving: false,
      errors: {},
      modalOpen: false
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getProbablyRange = this.getProbablyRange.bind(this);
  }

  componentDidMount() {
    this.getProbablyRange();
  }

  onUpdate(data){
    this.setState({
      data: {
        ...this.state.data,
        ...data
      }
    }, this.getProbablyRange)
  }

  getProbablyRange(){
    if(this.state.axiosCancelTokenSource !== false){
      this.state.axiosCancelTokenSource.cancel();
    }

    this.setState({
      probablyRange: false,
    });

    this.setState({
      axiosCancelTokenSource: CancelToken.source(),
    }, () => {
      if (this.props.collector.minAge && this.props.collector.maxAge) {
        api.surveyCollector.panel.get.posibilityRange(_.omit(this.state.data, ['guid', 'id', 'collectorResponses', 'endAt', 'startAt']), this.state.axiosCancelTokenSource)
            .then(data => {
              this.setState({
                probablyRange: data.count,
              })
            }).catch(e => {
          if (axios.isCancel(e)) {
            this.setState({
              probablyRange: false,
            });
          } else {
            let state = {
              probablyRange: 0,
            };

            if (e.response) {
              state.errors = e.response.data.form.errors;
            }

            this.setState(state);
          }

        });
      }
    });
  }

  bulkEdit(data) {
    this.setState({...this.state, saving: true, errors: {}});
    let bulkEditData = {};
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        bulkEditData[key] = data[key];
      } else if (Array.isArray(data[key]) === false && data[key] !== null) {
        bulkEditData[key] = data[key];
      }
    });

    api.surveyCollector.bulk(this.props.collector.ids, bulkEditData)
    .then(() => {
      this.setState({saving: false, modalOpen: false});
      toast.success(<FormattedMessage id="collectors.bulkEditForm.toast.success" />);
    })
    .catch(e => {
      this.setState({
        saving: false,
        errors: _.has(e, 'response.data.form.errors') ? e.response.data.form.errors : {},
      })
    });
  }

  onSubmit() {
    let data = {
      id: this.props.collector.id,
      surveyCollector: this.state.data
    };

    if (this.props.bulkEdit === true) {
      this.setState({modalOpen: true});
    } else {
      this.setState({...this.state, saving: true, errors: {}});
      api.surveyCollector.patch(data)
          .then(collector => {
            this.setState({saving: false});
            this.props.handleUpdate(collector);
          })
          .catch(e => {
            this.setState({
              errors: e.response.data.form.errors,
              saving: false
            })
          });
    }
  }

  render() {
    const {data, errors, probablyRange, modalOpen} = this.state;
    const {bulkEdit} = this.props;

    return (
      <React.Fragment>
        {bulkEdit === false && <FormDefaultProperty
          errors={errors}
          collector={data}
          onPropertyChanged={(name, value) => {
            this.setState({
              data: {
                ...data,
                [name]: value,
              }
            })
          }}
        />}

        {bulkEdit === false && <Row>
          <Col>
            <FormGroup row>
              <Label md={4}><FormattedMessage tagName="h2" id="collectorPanel.label.countScreenouts" /></Label>
              <Col md={8}>
                <Switch onChange={value => this.setState({data: {...this.state.data, countScreenouts: value}})} checked={data.countScreenouts} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}><h2>Czy kolektor jest ukryty dla panelistów?</h2></Label>
              <Col md={8}>
                <Switch onChange={value => this.setState({data: {...this.state.data, isHidden: value}})} checked={data.isHidden} />
              </Col>
            </FormGroup>
          </Col>
        </Row>}

        <ModalSimple onAccept={() => this.bulkEdit(this.state.data)}
            onCancel={() => {this.setState({modalOpen: false})}}
            modalOpen={modalOpen} headerText={"Masowa edycja"}
            bodyText={"Czy na pewno chcesz dokonać grupowej zmiany kolektorów?"}/>

        <CollectorPanelForm
          probablyRange={probablyRange}
          data={this.state.data}
          bulkEdit={bulkEdit}
          handleUpdate={bulkEdit ? this.props.handleUpdate : this.onUpdate}
          errors={errors}
        />

        <div className="direction-group">
          <div className="direction-right d-md-flex justify-content-md-end">
            <FormattedMessage id="_.button.save">{msg => <LaddaButton
              className="btn btn-primary btn-ladda mt-3 mb-0 float-right"
              loading={this.state.saving}
              onClick={this.onSubmit}
              data-style={ZOOM_OUT}
            >
              {msg}
            </LaddaButton>}</FormattedMessage>
          </div>
        </div>


      </React.Fragment>
    )
  }
}

TabTarget.defaultProps = {
  bulkEdit: false
}

TabTarget.propTypes = {
  collector: PropTypes.shape({
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  bulkEdit: PropTypes.bool.isRequired
};

export default TabTarget;
